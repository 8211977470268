import React from "react";

export default function Student(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
      <path
        d="M12.5 7.5H6.25C4.86929 7.5 3.75 8.61929 3.75 10V21.25C3.75 22.6307 4.86929 23.75 6.25 23.75H23.75C25.1307 23.75 26.25 22.6307 26.25 21.25V10C26.25 8.61929 25.1307 7.5 23.75 7.5H17.5M12.5 7.5V6.25C12.5 4.86929 13.6193 3.75 15 3.75C16.3807 3.75 17.5 4.86929 17.5 6.25V7.5M12.5 7.5C12.5 8.88071 13.6193 10 15 10C16.3807 10 17.5 8.88071 17.5 7.5M11.25 17.5C12.6307 17.5 13.75 16.3807 13.75 15C13.75 13.6193 12.6307 12.5 11.25 12.5C9.86929 12.5 8.75 13.6193 8.75 15C8.75 16.3807 9.86929 17.5 11.25 17.5ZM11.25 17.5C12.8828 17.5 14.2718 18.5435 14.7866 20M11.25 17.5C9.61723 17.5 8.22811 18.5435 7.71332 20M18.75 13.75H22.5M18.75 18.75H21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import * as React from "react";
const Inscription = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M11.78 16.97a.75.75 0 1 0-1.06 1.06l1.06-1.06ZM13.75 20l-.53.53a.75.75 0 0 0 1.06 0l-.53-.53Zm5.53-4.47a.75.75 0 1 0-1.06-1.06l1.06 1.06ZM23 8.75v15h1.5v-15H23ZM21.25 25.5H8.75V27h12.5v-1.5ZM7 23.75v-15H5.5v15H7ZM8.75 7h2.5V5.5h-2.5V7Zm10 0h2.5V5.5h-2.5V7Zm-10 18.5A1.75 1.75 0 0 1 7 23.75H5.5A3.25 3.25 0 0 0 8.75 27v-1.5ZM23 23.75a1.75 1.75 0 0 1-1.75 1.75V27a3.25 3.25 0 0 0 3.25-3.25H23Zm1.5-15a3.25 3.25 0 0 0-3.25-3.25V7c.966 0 1.75.784 1.75 1.75h1.5ZM7 8.75C7 7.784 7.784 7 8.75 7V5.5A3.25 3.25 0 0 0 5.5 8.75H7Zm3.72 9.28 2.5 2.5 1.06-1.06-2.5-2.5-1.06 1.06Zm3.56 2.5 5-5-1.06-1.06-5 5 1.06 1.06ZM13.75 4.5h2.5V3h-2.5v1.5Zm2.5 3.5h-2.5v1.5h2.5V8Zm-2.5 0A1.75 1.75 0 0 1 12 6.25h-1.5a3.25 3.25 0 0 0 3.25 3.25V8ZM18 6.25A1.75 1.75 0 0 1 16.25 8v1.5a3.25 3.25 0 0 0 3.25-3.25H18ZM16.25 4.5c.966 0 1.75.784 1.75 1.75h1.5A3.25 3.25 0 0 0 16.25 3v1.5ZM13.75 3a3.25 3.25 0 0 0-3.25 3.25H12c0-.966.784-1.75 1.75-1.75V3Z"
    />
  </svg>
);
export default Inscription;

import * as React from "react";

const Puzzle = (props) => (
  <svg width={64} height={64} viewBox="0 0 64 64" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M31.668 8.33341C31.668 4.65152 34.6527 1.66675 38.3346 1.66675C42.0165 1.66675 45.0013 4.65152 45.0013 8.33341V11.6667C45.0013 13.5077 46.4937 15.0001 48.3346 15.0001H58.3346C60.1756 15.0001 61.668 16.4925 61.668 18.3334V28.3334C61.668 30.1744 60.1756 31.6667 58.3346 31.6667H55.0013C51.3194 31.6667 48.3346 34.6515 48.3346 38.3334C48.3346 42.0153 51.3194 45.0001 55.0013 45.0001H58.3346C60.1756 45.0001 61.668 46.4925 61.668 48.3334V58.3334C61.668 60.1744 60.1756 61.6667 58.3346 61.6667H48.3346C46.4937 61.6667 45.0013 60.1744 45.0013 58.3334V55.0001C45.0013 51.3182 42.0165 48.3334 38.3346 48.3334C34.6527 48.3334 31.668 51.3182 31.668 55.0001V58.3334C31.668 60.1744 30.1756 61.6667 28.3346 61.6667H18.3346C16.4937 61.6667 15.0013 60.1744 15.0013 58.3334V48.3334C15.0013 46.4925 13.5089 45.0001 11.668 45.0001H8.33463C4.65274 45.0001 1.66797 42.0153 1.66797 38.3334C1.66797 34.6515 4.65274 31.6667 8.33463 31.6667H11.668C13.5089 31.6667 15.0013 30.1744 15.0013 28.3334V18.3334C15.0013 16.4925 16.4937 15.0001 18.3346 15.0001H28.3346C30.1756 15.0001 31.668 13.5077 31.668 11.6667V8.33341Z"
    />
  </svg>
);

export default Puzzle;

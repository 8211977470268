import * as React from "react";
const Support = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M18.375 11A7.375 7.375 0 0 1 11 18.375v1.75A9.125 9.125 0 0 0 20.125 11h-1.75ZM11 18.375A7.375 7.375 0 0 1 3.625 11h-1.75A9.125 9.125 0 0 0 11 20.125v-1.75ZM3.625 11A7.375 7.375 0 0 1 11 3.625v-1.75A9.125 9.125 0 0 0 1.875 11h1.75ZM11 3.625A7.375 7.375 0 0 1 18.375 11h1.75A9.125 9.125 0 0 0 11 1.875v1.75ZM13.792 11A2.792 2.792 0 0 1 11 13.792v1.75A4.542 4.542 0 0 0 15.542 11h-1.75ZM11 13.792A2.792 2.792 0 0 1 8.208 11h-1.75A4.542 4.542 0 0 0 11 15.542v-1.75ZM8.208 11A2.792 2.792 0 0 1 11 8.208v-1.75A4.542 4.542 0 0 0 6.458 11h1.75ZM11 8.208A2.792 2.792 0 0 1 13.792 11h1.75A4.542 4.542 0 0 0 11 6.458v1.75Zm5.215-3.66-3.241 3.24 1.237 1.238 3.241-3.24-1.237-1.238Zm-3.241 9.663 3.24 3.241 1.238-1.237-3.24-3.241-1.238 1.237ZM9.026 7.79l-3.24-3.241-1.238 1.237 3.24 3.241 1.238-1.237Zm-1.237 5.185-3.241 3.24 1.237 1.238 3.241-3.24-1.237-1.238Z"
    />
  </svg>
);
export default Support;
